import LeavesApprovalsTable from "./parts/LeaveApprovalsTable";
import LeaveApprovalsLoader from "./parts/Loaders/LeaveApprovalsLoader";
import { CardLayout } from "components/CardLayout";

const LeaveApprovals: React.FC = () => {
  return (
    <section id="aprobaciones" className="pt-3">
      <CardLayout>
        <LeaveApprovalsLoader>
          {(data) => {
            return (
              <>
                <div className="text-light fw-400 fs-18 mb-5">
                  A continuación se muestran las solicitudes de otros
                  colaboradores que requieren de tu aprobación.
                </div>
                <LeavesApprovalsTable
                  data={data.leaveApprovals}
                  onRefresh={data.refresh}
                />
              </>
            );
          }}
        </LeaveApprovalsLoader>
      </CardLayout>
    </section>
  );
};

export default LeaveApprovals;
