import request from "api/request2";
import {
  CalculateWorkingDaysResponse,
  LeaveRequest,
  LeavesResponse,
} from "./type";

const LEAVES_MAINTAINER = {
  DATA: "/leaves",
  CALCULATE_WORKING_DAYS: "/leaves/calculate-working-days",
  NEW_LEAVE: "/leaves/new-leave",
  DELETE: "/leaves/delete",
};

export function getData() {
  return request<LeavesResponse>(LEAVES_MAINTAINER.DATA, {
    method: "GET",
  });
}

export async function CalculateWorkingDays(data: {
  date_ini: Date | string;
  date_fin: Date | string;
}) {
  return request<CalculateWorkingDaysResponse>(
    LEAVES_MAINTAINER.CALCULATE_WORKING_DAYS,
    {
      method: "POST",
      data,
    }
  );
}

export async function newLeave(data: LeaveRequest) {
  return request(LEAVES_MAINTAINER.NEW_LEAVE, {
    method: "POST",
    data,
  });
}

export async function deleteLeave(id: string) {
  return request(`${LEAVES_MAINTAINER.DELETE}/${id}`, {
    method: "DELETE",
  });
}
