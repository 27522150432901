import {
  Alert,
  Button,
  DateInput,
  TextAreaInput,
  TextInput,
  Select,
} from "@octano/global-ui";
import { useLeaveRequestForm } from "./hooks/useLeaveRequestForm";
import { Authorizers } from "../type";
import { useState, useEffect } from "react";

export interface NewLeaveProps {
  authorizers: Authorizers[];
  adminDays: number;
  refresh: () => Promise<void>;
}

export const NewLeave: React.FC<NewLeaveProps> = ({
  authorizers,
  adminDays,
  refresh,
}) => {
  const [authorizerType, setAuthorizerType] = useState<"JERARQUIA" | "PERSONA">(
    "JERARQUIA"
  );

  const hasPersonaAuthorizers = authorizers.some(
    (auth) => auth.TIPO_AUTORIZADOR === "PERSONA"
  );

  const {
    control,
    setValue,
    isVacation,
    isAdminDays,
    isFullDay,
    isHalfDay,
    date_ini,
    workingDays,
    workingDaysError,
    authorizerEmailError,
    warningText,
    isLoading,
    disabledSubmit,
    onSubmit,
    modalClose,
  } = useLeaveRequestForm({ authorizers, adminDays, refresh });

  const getFirstAuthorizerByType = (type: "JERARQUIA" | "PERSONA") => {
    return authorizers.find(
      (auth) => auth.TIPO_AUTORIZADOR === type && auth.ORDEN !== "SUBROGANTE"
    );
  };

  useEffect(() => {
    const firstAuthorizer = getFirstAuthorizerByType(authorizerType);
    if (firstAuthorizer) {
      setValue("authorizer", {
        label: `${firstAuthorizer.NOMBRE_AUT} - ${firstAuthorizer.CORREO_AUT}`,
        value: firstAuthorizer.CORREO_AUT,
      });
    }
  }, [authorizerType, setValue]);

  const filteredAuthorizers = authorizers.filter(
    (auth) =>
      auth.TIPO_AUTORIZADOR === authorizerType && auth.ORDEN !== "SUBROGANTE"
  );

  const authorizerTypeOptions = [
    { label: "Jefatura", value: "JERARQUIA" },
    ...(hasPersonaAuthorizers
      ? [{ label: "Autorizadores", value: "PERSONA" }]
      : []),
  ];

  return (
    <div className="px-1">
      <h4 className="py-3 fs-22 text-dark text-center">
        Nueva solicitud de {isVacation ? "vacaciones" : "días administrativos"}
      </h4>
      <span className="modal-subtitle">
        La solicitud será enviada para su posterior aprobación.
      </span>
      <form onSubmit={onSubmit} className="container">
        <div className="row">
          <div className="col p-0">
            <Select
              name="requestType"
              label="TIPO DE PERMISO"
              control={control}
              options={[
                { label: "Vacaciones", value: 0 },
                { label: "Días administrativos", value: 1 },
              ]}
              rules={{ required: true }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col p-0">
            <Select
              name="authorizerType"
              label="TIPO DE AUTORIZADOR"
              value={authorizerTypeOptions.find(
                (opt) => opt.value === authorizerType
              )}
              onChange={(option) =>
                setAuthorizerType(option.value as "JERARQUIA" | "PERSONA")
              }
              options={authorizerTypeOptions}
              disabled={!hasPersonaAuthorizers}
            />
          </div>
        </div>

        {authorizerType === "PERSONA" && (
          <div className="row">
            <div className="col p-0">
              <Select
                name="authorizer"
                label="AUTORIZADOR DE LA SOLICITUD"
                control={control}
                options={filteredAuthorizers.map((auth) => ({
                  label: `${auth.NOMBRE_AUT} - ${auth.CORREO_AUT}`,
                  value: auth.CORREO_AUT,
                }))}
                rules={{ required: true }}
                errorText={authorizerEmailError}
              />
            </div>
          </div>
        )}

        {/* VACACIONES */}
        {isVacation && (
          <>
            <div className="row p-0 input-gap">
              <div className="col-12 col-sm p-0 mr-2">
                <DateInput
                  label="FECHA INICIO"
                  name="date_ini"
                  control={control}
                  rules={{ required: "Este campo es requerido" }}
                />
              </div>
              <div className="col-12 col-sm p-0 ml-2">
                <DateInput
                  label="FECHA TERMINO"
                  name="date_fin"
                  control={control}
                  rules={{ required: "Este campo es requerido" }}
                  minDate={date_ini instanceof Date ? date_ini : undefined}
                  disabled={!date_ini}
                />
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <TextInput
                  label="Días Hábiles"
                  name="workingDays"
                  value={workingDays}
                  disabled
                  errorText={workingDaysError}
                />
              </div>
            </div>
          </>
        )}

        {/* DÍAS ADMINISTRATIVOS */}
        {isAdminDays && (
          <>
            <div className="row">
              <div className="col p-0">
                <Select
                  name="requestFormat"
                  label="FORMATO DE SOLICITUD"
                  control={control}
                  options={[
                    { label: "Días completos", value: "full" },
                    { label: "Medio día", value: "half" },
                  ]}
                  rules={{ required: true }}
                />
              </div>
            </div>

            {isFullDay && (
              <div className="row p-0 input-gap">
                <div className="col-12 col-sm p-0 mr-2">
                  <DateInput
                    label="FECHA INICIO"
                    name="date_ini"
                    control={control}
                    rules={{ required: "Este campo es requerido" }}
                  />
                </div>
                <div className="col-12 col-sm p-0 ml-2">
                  <TextInput
                    label="Cantidad de días"
                    name="daysAmount"
                    control={control}
                    rules={{
                      required: "Este campo es requerido",
                      min: { value: 1, message: "Debe ser mayor a 0" },
                      validate: (value) => {
                        const numValue = Number(value);
                        if (isNaN(numValue)) {
                          return "La cantidad de días debe ser un número";
                        }
                        if (numValue > adminDays) {
                          return `La cantidad de días solicitados supera los días administrativos disponibles (${adminDays}).`;
                        }
                        return numValue > 0;
                      },
                    }}
                  />
                </div>
              </div>
            )}

            {isHalfDay && (
              <div className="row p-0 input-gap">
                <div className="col-12 col-sm p-0 mr-2">
                  <DateInput
                    label="FECHA"
                    name="date_ini"
                    control={control}
                    rules={{ required: "Este campo es requerido" }}
                  />
                </div>
                <div className="col-12 col-sm p-0 mr-2">
                  <Select
                    name="halfDaySchedule"
                    label="Horario"
                    control={control}
                    options={[
                      { label: "Mañana", value: "morning" },
                      { label: "Tarde", value: "afternoon" },
                    ]}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            )}
          </>
        )}

        <div className="row">
          <div className="col p-0">
            <TextAreaInput
              label="Motivo/Descripción"
              name="motive"
              control={control}
            />
          </div>
        </div>

        {warningText && (
          <div className="row mt-4">
            <div className="col p-0">
              <Alert
                color="info"
                icon="information"
                size="lg"
                text={warningText}
              />
            </div>
          </div>
        )}
      </form>

      <div className="modal-action pt-3">
        <Button
          text="Cancelar"
          outlined
          size="lg"
          fullwidth
          className="modal-action-btn"
          onClick={modalClose}
        />
        <Button
          text="Enviar SOLICITUD"
          size="lg"
          fullwidth
          onClick={onSubmit}
          className="modal-action-btn"
          loading={isLoading}
          disabled={disabledSubmit}
        />
      </div>
    </div>
  );
};
