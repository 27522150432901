import { Modal } from "@octano/global-ui";
import { LeaveApprovalForm } from "./LeaveApprovalForm";
import { LeaveApprovalRequest } from "../types";

interface LeaveApprovalModalProps {
  show: boolean;
  recordToEdit: LeaveApprovalRequest | null;
  toggleModal: () => void;
  onRefresh: () => void;
}

export const LeaveApprovalModal = ({
  recordToEdit,
  show,
  toggleModal,
  onRefresh,
}: LeaveApprovalModalProps) => {
  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      {recordToEdit && (
        <LeaveApprovalForm
          recordToEdit={recordToEdit}
          onToggle={toggleModal}
          onRefresh={onRefresh}
        />
      )}
    </Modal>
  );
};
