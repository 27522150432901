import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addToast } from "@octano/global-ui";
import { useModal } from "hooks";
import dayjs from "dayjs";
import { newLeave, CalculateWorkingDays } from "../../api";
import { Authorizers } from "../../type";

interface LeaveFormData {
  date_ini: Date | null;
  date_fin: Date | null;
  motive: string;
  requestType: { label: string | null; value: number | null };
  requestFormat: { label: string | null; value: string | null };
  halfDaySchedule: { label: string | null; value: string | null };
  daysAmount: number | null;
  authorizer: {
    label: string | null;
    value: string | null;
  };
}

interface UseLeaveRequestFormProps {
  authorizers: Authorizers[];
  adminDays: number;
  refresh: () => Promise<void>;
}

export const useLeaveRequestForm = ({
  authorizers,
  refresh,
}: UseLeaveRequestFormProps) => {
  const { modalClose } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const [workingDays, setWorkingDays] = useState<string | undefined>(undefined);
  const [workingDaysError, setWorkingDaysError] = useState<string | undefined>(
    undefined
  );
  const [authorizerEmailError, setAuthorizerEmailError] = useState<
    string | undefined
  >(undefined);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

  const firstAuthorizer = authorizers.find(
    (authorizer) => authorizer.TIPO_AUTORIZADOR === "JERARQUIA"
  );

  const { control, handleSubmit, watch, setValue } = useForm<LeaveFormData>({
    defaultValues: {
      date_ini: null,
      date_fin: null,
      motive: "",
      requestType: { label: null, value: null },
      requestFormat: { label: null, value: null },
      halfDaySchedule: { label: null, value: null },
      daysAmount: null,
      authorizer: {
        label: `${firstAuthorizer?.NOMBRE_AUT} - ${authorizers[0].CORREO_AUT}`,
        value: firstAuthorizer?.CORREO_AUT,
      },
    },
  });

  const [
    date_ini,
    date_fin,
    authorizer,
    requestType,
    requestFormat,
    halfDaySchedule,
    daysAmount,
  ] = watch([
    "date_ini",
    "date_fin",
    "authorizer",
    "requestType",
    "requestFormat",
    "halfDaySchedule",
    "daysAmount",
  ]);

  const calculateWorkingDays = async (
    date_ini: Date | null,
    date_fin: Date | null
  ) => {
    if (
      date_ini instanceof Date &&
      date_fin instanceof Date &&
      date_ini <= date_fin
    ) {
      setWorkingDays("...");
      setWorkingDaysError(undefined);
      try {
        const response = await CalculateWorkingDays({
          date_ini: dayjs(date_ini).format("YYYY-MM-DD"),
          date_fin: dayjs(date_fin).format("YYYY-MM-DD"),
        });

        setWorkingDays(response.data?.p_cantidad?.toString() || undefined);

        if (response.data?.p_det_error) {
          setWorkingDaysError(response.data?.p_det_error);
        }
      } catch {
        setWorkingDays(undefined);
      }
    } else {
      setWorkingDays("");
    }
  };

  const validateAuthorizerEmail = (authorizer: { value: string | null }) => {
    if (!authorizer.value) {
      setAuthorizerEmailError(
        "El responsable asignado no posee correo institucional. Para continuar, por favor contacta a la administración para que se le sea asignado uno."
      );
    } else {
      setAuthorizerEmailError(undefined);
    }
  };

  const getWarningText = (date_ini: Date | null): string | undefined => {
    if (date_ini && dayjs().isAfter(date_ini)) {
      return "Estás haciendo una solicitud para una fecha pasada. Debes tener una autorización escrita de tu jefatura para poder hacer esto.";
    }
    return undefined;
  };

  useEffect(() => {
    setValue("date_fin", null);
  }, [date_ini, setValue]);

  useEffect(() => {
    calculateWorkingDays(date_ini, date_fin);
  }, [date_ini, date_fin]);

  useEffect(() => {
    validateAuthorizerEmail(authorizer);
  }, [authorizer]);

  useEffect(() => {
    if (
      requestType?.label === "Días administrativos" &&
      requestFormat?.value === "half"
    ) {
      setValue("daysAmount", 1);
    }
  }, [requestFormat, requestType, setValue]);

  useEffect(() => {
    if (requestType?.label === "Vacaciones") {
      setDisabledSubmit(
        !workingDays || !!workingDaysError || !!authorizerEmailError
      );
    } else if (requestType?.label === "Días administrativos") {
      setDisabledSubmit(
        !daysAmount || daysAmount < 1 || isNaN(daysAmount) || !!workingDaysError
      );
    }
  }, [workingDays, workingDaysError, authorizerEmailError, daysAmount]);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const authorizer = authorizers.find(
        (auth) => auth.CORREO_AUT === data.authorizer.value
      );

      const isVacation = data.requestType.label === "Vacaciones";
      const reqParams = {
        startDate: dayjs(data.date_ini).format("YYYY-MM-DD"),
        endDate: isVacation
          ? dayjs(data.date_fin).format("YYYY-MM-DD")
          : undefined,
        isFullDay: isVacation ? true : requestFormat?.value === "full",
        isMorning: isVacation ? null : halfDaySchedule.value === "morning",
        quantity: isVacation ? parseInt(workingDays || "0") : daysAmount ?? 0,
        requestType: isVacation ? "Vacaciones" : "Días administrativos",
        comment: data.motive,
        authorizerRol: authorizer?.AUTORIZADOR || "",
        authorizerEmail: authorizer?.CORREO_AUT || "",
        authorizerName: authorizer?.NOMBRE_AUT || "",
      };

      const response = await newLeave(reqParams);

      if (!response.error) {
        addToast({
          text: "Solicitud enviada con éxito",
          autoClose: 5000,
          icon: "success",
          color: "success",
        });
        refresh();
        modalClose();
      } else {
        throw new Error("Error al enviar la solicitud");
      }
    } catch (error) {
      addToast({
        text: "Error al enviar la solicitud",
        autoClose: 5000,
        icon: "error",
        color: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  });

  return {
    control,
    setValue,
    isVacation: requestType?.label === "Vacaciones",
    isAdminDays: requestType?.label === "Días administrativos",
    isFullDay: requestFormat?.value === "full",
    isHalfDay: requestFormat?.value === "half",
    date_ini,
    workingDays,
    workingDaysError,
    authorizerEmailError,
    warningText: getWarningText(date_ini),
    isLoading,
    disabledSubmit,
    onSubmit,
    modalClose,
  };
};
