import { addToast, Button, Icon, Modal } from "@octano/global-ui";
import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { deleteLeave } from "views/Leaves/api";

interface ConfirmationModalProps {
  show: boolean;
  requestId: string | undefined;
  toggleModal: () => void;
  refresh: () => Promise<void>;
}

const ConfirmationModal = ({
  show,
  requestId,
  toggleModal,
  refresh,
}: ConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmDelete = async (requestId: string) => {
    setIsLoading(true);

    try {
      await deleteLeave(requestId);
      addToast({
        text: "Solicitud eliminada con exito",
        autoClose: 5000,
        icon: "success",
        color: "success",
      });
    } catch (error) {
      addToast({
        text: "Error al eliminar la solicitud",
        autoClose: 5000,
        icon: "error",
        color: "danger",
      });
    } finally {
      setIsLoading(false);
      refresh();
      toggleModal();
    }
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} size="md" unmountOnClose>
      <Container fluid className="text-center">
        <Icon name="information" size={45} />
        <h1 className="text-dark fs-20 mt-3 mb-4">
          ¿Estás seguro de eliminar la solicitud?
        </h1>
        <div>La solicitud dejará de ser visible para todos los usuarios.</div>
        <div>Esta acción no se puede deshacer.</div>

        <Row className="d-flex justify-content-around mt-5">
          <Col xs={12} md={6}>
            <Button
              outlined
              text="Cancelar"
              onClick={toggleModal}
              fullwidth
              loading={isLoading}
            />
          </Col>
          <Col xs={12} md={6}>
            <Button
              text="Confirmar"
              onClick={() => {
                handleConfirmDelete(requestId || "");
                toggleModal();
              }}
              fullwidth
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
