// Vendors
import { FC } from "react";
import { useSelector } from "react-redux";
import { Alert, Button, TextInput } from "@octano/global-ui";
import { useForm } from "react-hook-form";

// Components
import { UniversityLogo } from "../../../components/UniversityLogo";

// Hooks
import { useModal } from "hooks/useModal";

// Reducer
import { RootState } from "store";
import { ModalActivateAccount } from "./ModalActiveAccount";
import { ModalRecovery } from "./ModalRecovery";

interface IFormInput {
  email: string;
  password: string;
}

interface LoginFormProps {
  onHandleSubmit: (param: IFormInput) => void;
}

export const LoginForm: FC<LoginFormProps> = ({ onHandleSubmit }) => {
  const {
    loading: isLoading,
    errorMessage,
    serverErrors,
  } = useSelector((state: RootState) => state.authReducer.login);

  const { control, handleSubmit } = useForm<IFormInput>();

  const onSubmit = handleSubmit((data) => {
    onHandleSubmit(data);
  });

  const { modalOpen } = useModal();

  return (
    <section id="formulario-login" className="pt-3">
      <div className="d-grid gap-3">
        <UniversityLogo />
        <h3 className="text-center fs-16 text-gray fw-400">
          Para iniciar sesión debes ingresar tus credenciales.
        </h3>
      </div>

      <Alert
        isOpen={!isLoading && serverErrors}
        color="danger"
        fade
        size="sm"
        icon={"error"}
        text={`${errorMessage}`}
        className="bg-white"
      />

      <form onSubmit={onSubmit} className="d-grid gap-4">
        <TextInput label="Usuario" name="email" control={control} />

        <TextInput
          label="Contraseña"
          name="password"
          type="password"
          control={control}
          autocomplete=""
        />

        <Button
          loading={isLoading}
          text="Ingresar"
          size="lg"
          className="w-100"
          type="submit"
        />
      </form>
      <div className="login_actions">
        <button
          className="border-0 mx-auto d-block text-primary mt-4 bg-transparent "
          onClick={() => modalOpen(<ModalRecovery />)}
        >
          <u>¿Olvidaste tu contraseña?</u>
        </button>

        <span className="text-gray">
          ¿Eres nuevo usuario? Necesitas activar tu cuenta.
        </span>

        <button
          className="border-0 mx-auto d-block text-primary bg-transparent "
          onClick={() => modalOpen(<ModalActivateAccount />)}
        >
          <u>Activar cuenta</u>
        </button>
      </div>
    </section>
  );
};
