import { Button, Icon, Table } from "@octano/global-ui";
import {
  CellFormatOptions,
  ColumnTable,
} from "@octano/global-ui/dist/components/Table/types/TableTypes";
import { CellFormat } from "components/containers/CellFormat";
import { ErrorTableData } from "components/ErrorTableData";
import dayjs from "dayjs";
import { useTableData } from "hooks";
import { usePagination } from "hooks/usePagination";
import LeaveStateApproved from "./LeaveStateApproved";
import { useState } from "react";
import { LeaveRequestModal } from "./modals/LeaveRequestModal";
import ConfirmationModal from "./modals/ConfirmationModal";
import { LeaveRequest } from "../type";

export interface PermissionsHistoricTableProps {
  data: {
    createdAt: string;
    date: string;
    motive: string | null;
    requestedDays: number;
    approved: boolean | null;
  }[];
  refresh: () => Promise<void>;
}

export default function LeavesRequestsTable({
  data: dataProp,
  refresh,
}: PermissionsHistoricTableProps) {
  const pagination = usePagination({ totalItems: dataProp.length, limit: 5 });
  const [recordToShow, setRecordToShow] = useState<LeaveRequest | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const data = dataProp.slice(
    pagination.offset,
    pagination.offset + pagination.itemsPerPage
  );

  const columnsDesktop: ColumnTable[] = [
    {
      columnName: "requestType",
      headerText: <CellFormat>Tipo de solicitud</CellFormat>,
      cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
    },
    {
      columnName: "quantity",
      headerText: <CellFormat>Total de días hábiles</CellFormat>,
      cellFormat: ({ value, row }) => (
        <CellFormat>{row.isFullDay ? value : 0.5}</CellFormat>
      ),
    },
    {
      columnName: "requestDate",
      headerText: <CellFormat>Fecha de solicitud</CellFormat>,
      cellFormat: ({ value }) => (
        <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
      ),
    },
    {
      columnName: "startDate",
      headerText: <CellFormat>Fecha de inicio</CellFormat>,
      cellFormat: ({ value }) => (
        <CellFormat>
          {value ? dayjs(value).format("DD/MM/YYYY") : ""}
        </CellFormat>
      ),
    },
    {
      columnName: "endDate",
      headerText: <CellFormat>Fecha de término</CellFormat>,
      cellFormat: ({ value }) => (
        <CellFormat>
          {value ? dayjs(value).format("DD/MM/YYYY") : ""}
        </CellFormat>
      ),
    },
    {
      columnName: "comment",
      headerText: <CellFormat>Observación</CellFormat>,
      cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
    },
    {
      columnName: "approved",
      headerText: <CellFormat>Estado de Solicitud</CellFormat>,
      cellFormat: ({ value }) => {
        return (
          <CellFormat>
            <LeaveStateApproved approved={value} />
          </CellFormat>
        );
      },
    },
    {
      columnName: "id",
      headerText: <CellFormat>Ver detalle</CellFormat>,
      tdClassName: "pr-2 text-center",
      cellFormat: (options: CellFormatOptions) => (
        <>
          <span
            onClick={() => {
              setRecordToShow(options.row);
              setShowModal(true);
            }}
            className="cursor-pointer"
          >
            <Icon name="eye" size={20} />
          </span>
          {options.row.approved === null && (
            <span
              onClick={() => {
                setRecordToShow(options.row);
                setShowConfirmationModal(true);
              }}
              className="pl-3 cursor-pointer"
            >
              <Icon name="trash" size={20} />
            </span>
          )}
        </>
      ),
    },
  ];

  const { columns } = useTableData({
    columnsTable: columnsDesktop,
    columnsMobile: columnsDesktop,
    columnsDesktop,
  });

  return (
    <>
      <Table
        columns={columns}
        noResultsText={<ErrorTableData />}
        data={data}
        striped={false}
        pagination={pagination}
      />
      <LeaveRequestModal
        show={showModal}
        toggleModal={() => {
          setShowModal(!showModal);
        }}
        recordToShow={recordToShow}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        requestId={recordToShow?.id}
        toggleModal={() => {
          setShowConfirmationModal(!showConfirmationModal);
        }}
        refresh={refresh}
      />
    </>
  );
}
