import {
  Button,
  Modal,
  TextAreaInput,
  TextInput,
  Icon,
} from "@octano/global-ui";
import dayjs from "dayjs";
import { LeaveRequest } from "views/Leaves/type";

interface LeaveRequestModalProps {
  show: boolean;
  recordToShow: LeaveRequest | null;
  toggleModal: () => void;
}

export const LeaveRequestModal = ({
  recordToShow,
  show,
  toggleModal,
}: LeaveRequestModalProps) => {
  if (!recordToShow) return null;
  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <h4 className="py-2 fs-22 text-dark text-center">Detalle de solicitud</h4>
      {recordToShow.doneAt && (
        <div className="text-center">
          Fecha de respuesta: {dayjs(recordToShow.doneAt).format("DD/MM/YYYY")}
        </div>
      )}
      <div className="row px-3 pt-3 pb-2">
        <div className="col p-0 ">
          <TextInput
            name="requestType"
            label="TIPO DE SOLICITUD"
            value={recordToShow.requestType}
            rules={{ required: true }}
            disabled={true}
          />
        </div>
      </div>
      <div className="row px-3 py-3">
        <table className="g-table table table-borderless">
          <thead
            style={{
              backgroundColor: "#4a90d0",
              color: "white",
            }}
          >
            <tr>
              {recordToShow.requestType === "Días administrativos" && (
                <th>
                  <div className="mx-auto fw-400 pl-4">Tipo de permiso</div>
                </th>
              )}
              {recordToShow.isMorning !== null && (
                <th>
                  <div className="mx-auto fw-400 pl-4">Horario</div>
                </th>
              )}
              <th>
                <div className="text-center mx-auto fw-400">Fechas</div>
              </th>
              <th>
                <div className="text-center mx-auto fw-400">Cantidad</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {recordToShow.requestType === "Días administrativos" && (
                <td>
                  <div className="mx-auto fw-400 pl-4">
                    {recordToShow.isFullDay ? "Días completos" : "Medio día"}
                  </div>
                </td>
              )}
              {recordToShow.isMorning !== null && (
                <td>
                  <div className="mx-auto fw-400 pl-4">
                    {recordToShow.isMorning ? "Mañana" : "Tarde"}
                  </div>
                </td>
              )}
              <td>
                <div className="text-center mx-auto fw-400">
                  {dayjs(recordToShow.startDate).format("DD/MM/YYYY")}
                  {recordToShow.endDate &&
                    ` - ${dayjs(recordToShow.endDate).format("DD/MM/YYYY")}`}
                </div>
              </td>
              <td>
                <div className="text-center mx-auto fw-400">
                  {recordToShow.isFullDay ? recordToShow.quantity : 0.5}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {recordToShow.doneAt ? (
        <>
          <div className="row">
            <div className="col pb-5">
              <TextAreaInput
                label="Respuesta"
                name="comment"
                value={recordToShow.doneComment}
                disabled={true}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="w-100 mb-3">
              <div>
                <Icon name="information" color="info" />
                <b className="pl-2 fs-16">
                  Solicitud
                  {recordToShow.approved ? " aprobada " : " rechazada "}
                  por:{" "}
                </b>
              </div>
              <div className="py-3 pl-4">
                <b>
                  {recordToShow.doneBySurrogate
                    ? "Subrogante de Jefatura - "
                    : "Jefatura - "}
                </b>
                {recordToShow.doneBy &&
                  recordToShow.doneBy
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col pb-5">
            <TextAreaInput
              label="Observación"
              name="comment"
              value={recordToShow.comment}
              disabled={true}
            />
          </div>
        </div>
      )}
      <div className="modal-action pt-3">
        <Button
          text="Volver"
          outlined
          size="lg"
          fullwidth
          className="modal-action-btn"
          onClick={toggleModal}
        />
      </div>
    </Modal>
  );
};
