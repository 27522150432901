import { Select } from "@octano/global-ui";
import { LeaveFiltersState } from "../hooks/useLeaveFilters";

interface LeaveFiltersProps {
  filters: LeaveFiltersState;
  onFilterChange: (key: keyof LeaveFiltersState, value: any) => void;
  availableYears: Array<{ label: string; value: string }>;
}

export function LeaveFilters({
  filters,
  onFilterChange,
  availableYears,
}: LeaveFiltersProps) {
  return (
    <div className="row mb-3">
      <div className="col">
        <Select
          name="requestType"
          label="TIPO DE PERMISO"
          options={[
            { label: "Vacaciones", value: "Vacaciones" },
            { label: "Días administrativos", value: "Días administrativos" },
          ]}
          value={
            filters.requestType
              ? { label: filters.requestType, value: filters.requestType }
              : undefined
          }
          onChange={(value) => onFilterChange("requestType", value?.value)}
        />
      </div>
      <div className="col">
        <Select
          name="approved"
          label="ESTADO DE SOLICITUD"
          options={[
            { label: "Aprobada", value: "true" },
            { label: "Rechazada", value: "false" },
            { label: "Pendiente", value: "null" },
          ]}
          value={
            filters.approved !== undefined
              ? {
                  label:
                    String(filters.approved) === "true"
                      ? "Aprobada"
                      : String(filters.approved) === "false"
                      ? "Rechazada"
                      : "Pendiente",
                  value: String(filters.approved),
                }
              : undefined
          }
          onChange={(value) =>
            onFilterChange(
              "approved",
              value?.value === undefined
                ? undefined
                : value?.value === "true"
                ? true
                : value?.value === "false"
                ? false
                : null
            )
          }
        />
      </div>
      <div className="col">
        <Select
          name="year"
          label="AÑO"
          options={availableYears}
          value={
            filters.createdAt
              ? { label: filters.createdAt, value: filters.createdAt }
              : undefined
          }
          onChange={(value) => onFilterChange("createdAt", value?.value)}
        />
      </div>
    </div>
  );
}
