import Badge from "components/Badge";

export interface LeaveStateApprovedProps {
  approved?: boolean | null;
}

export default function LeaveStateApproved({
  approved,
}: LeaveStateApprovedProps) {
  if (approved === true) {
    return <Badge color="success">Aprobada</Badge>;
  } else if (approved === null || approved === undefined) {
    return <Badge color="warning">Pendiente</Badge>;
  } else {
    return <Badge color="error">Rechazada</Badge>;
  }
}
