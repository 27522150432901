import { PermissionName } from "types/auth";
import { LinkWithPermissionsRouter, typeRoutes } from "types/route";

/**
 * Aquí se definen todas las rutas de la aplicacion
 * Es un array de objectos con la información necesaria para cada ruta
 * - label: es lo que se mostrara en la pestaña del navegador en la pagina actual
 * - path: es la ruta que hara macth para mostrar una pantalla
 * - compenent: ruta de component/modulo/page/view que reenderizara la vista actual (Lazy)
 * - type: tipo de rutas
 */

export const allRoutes: LinkWithPermissionsRouter[] = [
  {
    label: "Remuneraciones",
    path: "/liquidaciones",
    component: "Home",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.recoverShow,
      PermissionName.recoverShowLiquidationsTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Remuneraciones",
    path: "/asignaciones-haberes",
    component: "Home",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.recoverShow,
      PermissionName.recoverShowAssignmentAssetsTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Remuneraciones",
    path: "/descuentos",
    component: "Home",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.recoverShow,
      PermissionName.recoverShowDiscountsTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Contratos",
    path: "/contratos",
    component: "Contracts",
    type: typeRoutes.private,
    requiredPermissions: [PermissionName.contractsShow],
  },
  {
    label: "Contratos",
    path: "/contratos/detalles-contrato/:id",
    component: "DetailesContract",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.contractsShow,
      PermissionName.contractsShowDetailesContractTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Contratos",
    path: "/contratos/detalles-contrato/dependencia/:id",
    component: "DetailesContract",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.contractsShow,
      PermissionName.contractsShowDetailesContractDependencyTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Contratos",
    path: "/contratos/detalles-contrato/estructura-sueldo/:id",
    component: "DetailesContract",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.contractsShow,
      PermissionName.contractsShowDetailesContractSalaryTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Solicitudes",
    path: "/solicitudes",
    component: "Requests",
    type: typeRoutes.private,
    requiredPermissions: [PermissionName.requestsShow],
  },
  {
    label: "Certificados",
    path: "/certificados",
    component: "Certificates",
    type: typeRoutes.private,
    requiredPermissions: [PermissionName.certificatesShow],
  },
  {
    label: "Datos personales",
    path: "/datos-personales",
    component: "PersonalDate",
    type: typeRoutes.private,
    requiredPermissions: [PermissionName.personalDateShow],
  },
  {
    label: "Permisos y vacaciones",
    path: "/permisos",
    component: "Permits",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.permitsVacationsShow,
      PermissionName.permitsVacationsShowPermitsTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Permisos y vacaciones",
    path: "/vacaciones",
    component: "Vacations",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.permitsVacationsShow,
      PermissionName.permitsVacationsShowVacationsTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Recuperar contraseña",
    path: "/recuperar-acceso/:token",
    component: "Recover",
    type: typeRoutes.login,
  },
  {
    label: "Activar cuenta",
    path: "/activar-cuenta/:token",
    component: "Activate",
    type: typeRoutes.login,
  },
  {
    label: "Iniciar sesion",
    path: "/iniciar-sesion",
    component: "Login",
    type: typeRoutes.login,
  },
  {
    label: "Permisos y vacaciones",
    path: "/permisos-y-vacaciones",
    component: "Leaves",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.permitsVacationsShow,
      PermissionName.permitsVacationsShowPermitsTab,
    ],
    allPermisionsRequired: true,
  },
  {
    label: "Solicitudes",
    path: "/aprobacion-permisos",
    component: "LeaveApprovals",
    type: typeRoutes.private,
    requiredPermissions: [
      PermissionName.permitsVacationsShow,
      PermissionName.permitsVacationsShowPermitsTab,
    ],
    allPermisionsRequired: true,
  },
];
