// Vendors
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { Icon, Table } from "@octano/global-ui";
import { Link } from "react-router-dom";
import { ColumnTable } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import dayjs from "dayjs";

// Components
import { CardLayout } from "components/CardLayout";
import { TableFilter } from "./parts/TableFilter";
import { FieldStatus } from "../../components/containers/FieldStatus";
import { CellFormat } from "components/containers/CellFormat";

// Hooks
import { useTableData } from "hooks/useTableData";
import { usePagination } from "hooks/usePagination";

// Reducuer
import { RootState } from "store";
import { getContracts } from "reducers/ordsSlice";

// Types
import { paramsOrdsRequest } from "types/api";

const eyesIcon = (data: any) => {
  return (
    <Link
      to={{ pathname: `/contratos/detalles-contrato/${data[0]?.nro_contrato}` }}
    >
      <Icon
        name="eye"
        size="22px"
        color="inherit"
        className="icon-gray-light "
      />
    </Link>
  );
};
const columnsDesktop: ColumnTable[] = [
  {
    columnName: "nro_contrato",
    headerText: <CellFormat>Número contrato</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "nmbcargo",
    headerText: <CellFormat>Cargo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "nmbcategoria",
    headerText: <CellFormat>Tipo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "nmbduraccto",
    headerText: <CellFormat>Duración</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "contrato_desde",
    headerText: <CellFormat>Desde</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "contrato_hasta",
    headerText: <CellFormat>Hasta</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "num_horas_semanales",
    headerText: <CellFormat>Jornada hrs</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "estado",
    headerText: <CellFormat>Estado</CellFormat>,
    cellFormat: ({ value }) => (
      <div className="d-flex justify-content-center">
        <FieldStatus status={value === "A" ? "active" : "finished"} />
      </div>
    ),
  },
  {
    columnName: "nombre",
    headerText: "",
    cellFormat: ({ data }) => eyesIcon(data),
  },
];

const columnsTable: ColumnTable[] = [
  {
    columnName: "nro_contrato",
    headerText: <CellFormat>Número contrato</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "nmbcargo",
    headerText: <CellFormat>Cargo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "contrato_desde",
    headerText: <CellFormat>Desde</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "contrato_hasta",
    headerText: <CellFormat>Hasta</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "estado",
    headerText: <CellFormat>Estado</CellFormat>,
    cellFormat: ({ value }) => (
      <div className="d-flex justify-content-center">
        <FieldStatus status={value === "A" ? "active" : "finished"} />
      </div>
    ),
  },
  {
    columnName: "",
    headerText: "",
    cellFormat: ({ data }) => eyesIcon(data),
  },
];

const columnsMobile: ColumnTable[] = [
  {
    columnName: "nro_contrato",
    headerText: <CellFormat>Número contrato</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "nmbcargo",
    headerText: <CellFormat>Cargo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "estado",
    headerText: <CellFormat>Estado</CellFormat>,
    cellFormat: ({ value }) => (
      <div className="d-flex justify-content-center">
        <FieldStatus status={value === "A" ? "active" : "finished"} />
      </div>
    ),
  },
  {
    columnName: "",
    headerText: "",
    cellFormat: ({ data }) => eyesIcon(data),
  },
];

const Contracts: React.FC<{ title: string }> = () => {
  const dispatch = useDispatch();
  const { loading: isLoading, contracts } = useSelector(
    (state: RootState) => state.ordsReducer
  );

  const { columns } = useTableData({
    columnsDesktop,
    columnsTable,
    columnsMobile,
  });

  const pagination = usePagination({ totalItems: contracts.count });

  const handlerFilter = useCallback(
    (data: paramsOrdsRequest) => {
      dispatch(
        getContracts({
          ...data,
          offset: pagination.offset,
          limit: pagination.itemsPerPage,
        })
      );
    },
    [dispatch, pagination.offset, pagination.itemsPerPage]
  );

  return (
    <section id="remuneraciones" className="pt-3">
      <CardLayout>
        <TableFilter handlerSubmit={handlerFilter} />
        <div className="w-100">
          <Table
            isLoadingResults={isLoading}
            columns={columns}
            data={contracts.data}
            pagination={{
              currentPage: pagination.currentPage,
              itemsPerPage: pagination.itemsPerPage,
              onChangePage: pagination.onChangePage,
              totalItems: pagination.totalItems,
              totalPages: pagination.totalPages,
            }}
          />
        </div>
      </CardLayout>
    </section>
  );
};

export default Contracts;
