// Vendors
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastProvider } from "@octano/global-ui";

// Routes
import { allRoutes } from "config/routes";

// Components
import { ModalApp } from "components/containers/ModalApp";
import RoutesContainer from "components/containers/RoutesContainer";

// Views
import Home from "./views/Home/Home";
import Contracts from "./views/Contracts/Contracts";
import DetailesContract from "./views/Contracts/DetailesContract";
import Activate from "./views/Activate/Activate";
import Recover from "./views/Recover/Recover";
import Login from "./views/Login/Login";
import Requests from "./views/Requests/Requests";
import PersonalDate from "./views/PersonalDate/PersonalDate";
import Certificates from "./views/Certificates/Certificates";
import Permits from "./views/PermitsVacations/Permits";
import Vacations from "./views/PermitsVacations/Vacations";
import Leaves from "views/Leaves/Leaves";

// State
import { browserReload } from "reducers/authSlice";

// Context
import { ModalContextProvider } from "context/ModalContext";
import { AppExpiredAuth } from "components/AppExpiredAuth";
import { RootState } from "store";
import LeaveApprovals from "views/LeaveApprovals/LeaveApprovals";

const views = {
  Home,
  Contracts,
  Recover,
  Login,
  Activate,
  DetailesContract,
  PersonalDate,
  Requests,
  Certificates,
  Permits,
  Vacations,
  Leaves,
  LeaveApprovals,
};

function App() {
  const dispatch = useDispatch();

  // verify user to browserReload
  React.useEffect(() => {
    dispatch(browserReload());
  }, [dispatch]);

  const { loaded } = useSelector((state: RootState) => state.authReducer);

  return (
    <ToastProvider>
      <ModalContextProvider>
        {loaded ? (
          <RoutesContainer listRoutes={allRoutes} views={views} />
        ) : (
          <></>
        )}
        <AppExpiredAuth />
        <ModalApp />
      </ModalContextProvider>
    </ToastProvider>
  );
}

export default App;
