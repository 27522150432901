import { useState } from "react";
import { LeavesTooltip } from "./LeavesTooltip";
import { Icon } from "@octano/global-ui";
import { Holidays } from "../type";

interface LeavesSummaryProps {
  description: string;
  boxId: string;
  tooltipTitle: string;
  holidays: Holidays | null;
  total: number;
  code_error: string;
}

export const LeavesSummary: React.FC<LeavesSummaryProps> = ({
  description,
  boxId,
  tooltipTitle,
  holidays,
  code_error,
  total,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    if (holidays && code_error === "0") {
      setIsTooltipVisible(!isTooltipVisible);
    }
  };

  const iconStyle = {
    marginLeft: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    color: "#007bff",
  };

  const descriptionStyle = {
    fontSize: "12px",
    letterSpacing: "0px",
    color: "#838383",
    opacity: 1,
    lineHeight: "14px",
  };

  const numberStyle = {
    fontSize: "38px",
    color: "#0056b3",
    fontWeight: "bold",
  };

  const containerStyle = {
    display: "flex",
    gap: 15,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 20px 0px 20px",
    borderRadius: "4px",
    backgroundColor: "#F7F7F7",
    width: "220px",
    position: "relative" as const,
  };

  const parentStyle = {
    position: "relative" as const,
    display: "inline-block",
  };

  const errorStyle = {
    display: "flex",
    gap: 10,
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "8px",
  };

  return (
    <div style={parentStyle}>
      <div id={boxId} style={containerStyle}>
        {code_error === "0" ? (
          <>
            <span style={numberStyle}>{total}</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={descriptionStyle}>{description}</span>
              {holidays ? (
                <i style={iconStyle} onClick={toggleTooltip}>
                  <Icon name={"information"} size="20px" color="secondary" />
                </i>
              ) : (
                <span style={{ width: "20px" }}></span>
              )}
            </div>
          </>
        ) : (
          <div style={errorStyle}>
            <i style={iconStyle}>
              <Icon name={"error"} size="20px" color="warning" />
            </i>
            <div style={descriptionStyle}>
              No se pudo obtener la información. Por favor, comunicarse con
              soporte.
            </div>
          </div>
        )}
      </div>

      {isTooltipVisible && holidays && (
        <LeavesTooltip tooltipTitle={tooltipTitle} tooltipDays={holidays} />
      )}
    </div>
  );
};
