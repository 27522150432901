//Vendors
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@octano/global-ui";

//Components
import { CardLayout } from "components/CardLayout";
import { GroupTabs } from "components/containers/GroupTabs/GroupTabs";
import { NewPermit } from "./parts/NewPermit";

//Hooks
import { useModal } from "hooks";

// Constants
import { tabsPermitsVacations } from "config/constants";

// Store
import { RootState } from "store";
import { getPermissions } from "reducers/ordsSlice";
import { DaysInformation } from "./parts/DaysInformation";
import PermissionsHistoricTable from "./parts/PermissionsHistoricTable";
import PermissionsRequestsTable from "./parts/PermissionsRequestsTable";
import Loading from "components/Loading";

const Permits: React.FC = () => {
  const dispatch = useDispatch();

  const { modalOpen } = useModal();

  const { loading, permissions } = useSelector(
    (state: RootState) => state.ordsReducer
  );

  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);

  return (
    <section id="permisos" className="pt-3">
      <GroupTabs tabsInfo={tabsPermitsVacations} />
      <CardLayout>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="vacations-information">
              <div className="vacations-information-days">
                <DaysInformation
                  numberDays={permissions.p_disponibles || 0}
                  subtitle="Días disponibles periodo "
                  active
                />
              </div>
              <Button
                text="+ NUEVA SOLICITUD DE PERMISO"
                size="sm"
                className="vacations-information-btn"
                onClick={() =>
                  modalOpen(
                    <NewPermit availableDays={permissions.p_disponibles} />,
                    "lg"
                  )
                }
              />
            </div>

            <div>
              <h3 className="text-light fw-400 fs-20 mb-3">
                Solicitudes de Permisos
              </h3>
              <PermissionsRequestsTable
                data={permissions.permissionsRequests}
              />
            </div>
            <div>
              <h3 className="text-light fw-400 fs-20 mb-3">
                Histórico de Permisos
              </h3>
              <PermissionsHistoricTable
                data={permissions.permissionsRegistereds}
              />
            </div>
          </>
        )}
      </CardLayout>
    </section>
  );
};

export default Permits;
