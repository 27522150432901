import request from "api/request2";
import { LeaveApprovalsResponse } from "./types";

const LEAVE_APPROVALS_MAINTAINER = {
  DATA: "/leaves/approvals",
  RESPOND: "/leaves/approvals/respond",
};

export function getData() {
  return request<LeaveApprovalsResponse>(LEAVE_APPROVALS_MAINTAINER.DATA, {
    method: "GET",
  });
}

export async function handleLeaveRequest(
  leaveApprovalId: string,
  data: {
    approved: boolean;
    comment?: string;
  }
) {
  return request(`${LEAVE_APPROVALS_MAINTAINER.RESPOND}/${leaveApprovalId}`, {
    method: "PUT",
    data,
  });
}
