//Vendors
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Button } from "@octano/global-ui";

// Components
import { DaysInformation } from "./parts/DaysInformation";
import { CardLayout } from "components/CardLayout";
import { GroupTabs } from "components/containers/GroupTabs/GroupTabs";

// Hooks
import { useModal } from "hooks";
import { NewVacations } from "./parts/NewVacations";
import VacationsHistoricTable from "./parts/VacationsHistoricTable";
import VacationsRequestsTable from "./parts/VacationsRequestsTable";

// Constants
import { tabsPermitsVacations } from "config/constants";

//Store
import { getHolidays } from "reducers/ordsSlice";
import { RootState } from "store";
import Loading from "components/Loading";

const Vacations: React.FC = () => {
  const dispatch = useDispatch();
  const { modalOpen } = useModal();

  const { loading, holidays } = useSelector(
    (state: RootState) => state.ordsReducer
  );

  useEffect(() => {
    dispatch(getHolidays());
  }, [dispatch]);

  return (
    <section id="solicitudes" className="pt-3">
      <GroupTabs tabsInfo={tabsPermitsVacations} />
      <CardLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className="w-100">
            <div className="vacations-information">
              <div className="vacations-information-days">
                <DaysInformation
                  numberDays={holidays.p_disponibles || 0}
                  subtitle="Días disponibles periodo "
                  floor
                  active
                />
                <DaysInformation
                  numberDays={holidays.p_tomados_periodo || 0}
                  subtitle="Días tomados Periodo actual"
                />
                <DaysInformation
                  numberDays={holidays.p_acumulados || 0}
                  subtitle="Días acumulados periodo anterior"
                />
                <DaysInformation
                  numberDays={holidays.p_periodo || 0}
                  subtitle="Días acumulados periodo actual"
                  floor
                />
                <DaysInformation
                  numberDays={holidays.p_interrumpidos || 0}
                  subtitle="Días interrumpidos"
                  floor
                />
              </div>
              <Button
                text="+ NUEVA SOLICITUD DE VACACIONES"
                size="sm"
                className="vacations-information-btn"
                onClick={() => modalOpen(<NewVacations />, "lg")}
              />
            </div>
            <div>
              <h3 className="text-light fw-400 fs-20 mb-3">
                Solicitudes de Vacaciones
              </h3>
              <VacationsRequestsTable data={holidays.holidaysRequests} />
            </div>
            <div>
              <h3 className="text-light fw-400 fs-20 mb-3">
                Histórico de Vacaciones
              </h3>
              <VacationsHistoricTable data={holidays.holidaysRegistereds} />
            </div>
          </div>
        )}
      </CardLayout>
    </section>
  );
};

export default Vacations;
